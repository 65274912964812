import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import GoogleReCaptchaV2 from "react-google-recaptcha";

// const signUpEndPoint = "https://c5ba029c.sibforms.com/serve/MUIEAOWHX5a1OP7y51GwVJVGQjBGg1eybz-aqyDYDSi36JRCWOC68O1IUDS9uo8wMq0FvDYYeec1jYcFTSsaIusnrSztihw4cTQlWTcSXuoxdIhfP_uqviw2JCX_slFq0OG26sqkLq_j0hcOh-yaaYzP6d4NrlWko9Az3jrFMPajXxshY0ReiOIaN5P6p3rXd3UuQbAxyB5_aFXW?isAjax=1";
const signUpEndPoint = "https://c5ba029c.sibforms.com/serve/MUIEAD2uYZf8Q33in0QBdII7lXrYZEaD4umlPjBRuoeVH_jx1j4lD_DWzibG81fTwe6f8qe4ZxTnTVZEbNFR--I11FLeNqswhkOvuUaGeq7-Zkws5XJxMI8GXKwnpVSoFbtMLDb9uE0QvAKBnidrlT9U7ar299_njSpnkEYvT86jrAtA-WB3NnV6FyQxr7G2fl8UDDy20usBU5uz?isAjax=1";

const logoWidth = 457;
const inputWidth = 537;
const inputHeight = 90;

const fontSize = 36;
const smallFontSize = 26;

const BackgroundDiv = styled.div`
    width: 100vw;
    height: 100svh;

    display: flex;
    flex-direction: row;
    justify-content: center;

    background-image: url('img/Background.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const JellyPerv = styled.img`
    position: absolute;
    bottom: 0;

    width: ${logoWidth}px;
    max-width: 100vw;
`;

const JellyverseLogo = styled.img`
    position: absolute;
    top: 100px;
    
    width: ${logoWidth}px;
    max-width: 80vw;
`;

const SignUpSection = styled.div`
    width: 100%;
    height: 250px;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const SignUpButton = styled.button`
    width: ${inputWidth}px;
    max-width: 80vw;
    height: ${inputHeight}px;

    font-size: ${fontSize}px;
    @media only screen and (max-width: 768px) {
        font-size: ${smallFontSize}px;
    }

    background-color: #9879EE;
    border-radius: 45px;
    color: white;
    border: 1px;
    
    :hover {
        background-color: #FF33CC;
        cursor: pointer; 
    }
`;

const SignUpDiv = styled.div`
    position: relative;

    width: ${inputWidth}px;
    max-width: 80vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: ${inputHeight * 3}px;
`;

const SignUpForm = styled.form`
    position: relative;

    width: ${inputWidth}px;
    max-width: 80vw;
    height: ${inputHeight}px;

    border-width: 1px;
    border-color: white;
    border-style: solid;

    background-color: #9879EE;
    border-radius: 45px;
`;

const SignUpInputField = styled.input`
    position: relative;

    width: 90%;
    height: 90%;

    top: 4px;
    left: 29px;
    
    :focus {
        outline-width: 0;
        ${SignUpDiv} {
            border-width: 3px;
            border-color: blue;
        }
    }

    font-size: ${fontSize * 0.7}px;
    @media only screen and (max-width: 768px) {
        width: 80%;
        height: 80%;

        top: 8px;
        left: 10px;

        font-size: ${smallFontSize * 0.7}px;
    }

    color: white;
    border-width: 0px;
    background-color: transparent;
`;

const SignUpSubmitter = styled.button`
    position: absolute;
    right: 12px;
    top: 12px;

    width: 65px;
    height: 65px;

    background-color: transparent;
    border: 0;

    background-image: url('img/SubmitButton.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

    :hover {
        cursor: pointer; 
    }
`;

const SignUpSuccessDiv = styled.div`
    position: relative;

    width: ${inputWidth}px;
    max-width: 80vw;
    height: ${inputHeight}px;
    line-height: ${inputHeight}px;

    border-width: 1px;
    border-color: white;
    border-style: solid;

    font-size: ${fontSize}px;

    color: white;
    text-align: center;
    vertical-align: middle;

    background-color: #FF33CC;
    border-radius: 45px;

    @media only screen and (max-width: 768px) {
        padding-left: 22px;
        font-size: ${smallFontSize}px;
        text-align: left;
    }
`;

const SignUpError = styled.div`
    position: relative;

    width: ${inputWidth}px;
    max-width: 80vw;
    height: ${inputHeight}px;
    line-height: ${inputHeight}px;

    border-width: 1px;
    border-color: white;
    border-style: solid;

    font-size: ${fontSize}px;

    color: white;
    text-align: center;
    vertical-align: middle;

    background-color: #9879EE;
    border-radius: 45px;

    @media only screen and (max-width: 768px) {
        padding-left: 22px;
        font-size: ${smallFontSize}px;
        text-align: left;
    }

    :hover {
        cursor: pointer; 
    }
`;

const Mark = styled.img`
    position: absolute;
    right: 34px;
    top: 34px;

    width: 22px;
    height: 22px;
    
    @media only screen and (max-width: 768px) {
        right: 22px;
        top: 38px;

        width: 19px;
        height: 19px;
    }
`;

const FakeDiv = styled.div`
    width: 100%;
    height: 74px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const CheckEmailDiv = styled.div`
    width: 100%;

    color: white;
    // text-align: center;
    padding-left: 45px;
    vertical-align: middle;
`;

export default function LandingScreen() {
    const [email, setEmail] = useState("");
    const [signUpOpen, setSignUpOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);

    const recaptchaRef: React.MutableRefObject<GoogleReCaptchaV2 | null> = useRef(null);

    const clearStatus = useCallback(() => {
        setStatus(null);
        setError(null);
    }, []);

    const [verified, setVerified] = useState(false);
    const handleVerify = useCallback((token: string | null) => {
        setVerified(token != null);
    }, []);

    const submit = useCallback(async (event: any) => {
        event.preventDefault();

        if (!verified) {
            return;
        }

        let formData = new FormData();
        formData.append('EMAIL', email);
        const response = await fetch(signUpEndPoint, {
            method: "POST",
            body: formData
        });
        const responseJson = JSON.parse(await response.text());
        if (responseJson.success) {
            setStatus("success");
            setEmail("");
        } else {
            setStatus("failure");
            setError(responseJson.errors.EMAIL);
        }
    }, [email, verified]);

    return <BackgroundDiv>
        <JellyverseLogo src='/img/JellyverseLogo.png' />
        <SignUpSection>
            {!signUpOpen && status !== "success" &&
                <SignUpDiv>
                    <SignUpButton onClick={() => setSignUpOpen(true)}><b>sign up</b> for updates</SignUpButton>
                    <FakeDiv />
                </SignUpDiv>
            }
            {signUpOpen && !status &&
                <SignUpDiv>
                    <SignUpForm id="news_subscription_form" onSubmit={submit}>
                        <SignUpInputField autoFocus={true} value={email} onChange={(event) => setEmail(event.target.value)} />
                        <SignUpSubmitter disabled={!verified} type="submit" form="news_subscription_form" />
                    </SignUpForm>
                    <FakeDiv>
                        <GoogleReCaptchaV2
                            ref={recaptchaRef}
                            theme='dark'
                            size='normal'
                            hidden={verified}
                            onChange={handleVerify}
                            sitekey='6LfpQfshAAAAAJ1vIGoQS4OZ_VsXP-AOWVDR-Gyw'
                        // sitekey='6Lc_6N4lAAAAAKfBxs3YXFbupR9jMaQ7cfhDWxLt'
                        />
                    </FakeDiv>
                </SignUpDiv>
            }
            {status === "success" &&
                <SignUpDiv>
                    <SignUpSuccessDiv>
                        <b>successfully</b> signed up
                        <Mark src='/img/check.png' />
                    </SignUpSuccessDiv>
                    <FakeDiv>
                        <CheckEmailDiv>*check your email for confirmation</CheckEmailDiv>
                    </FakeDiv>
                </SignUpDiv>

            }
            {status === "failure" &&
                <SignUpDiv>
                    <SignUpError onClick={clearStatus}>
                        invalid format, try again
                        <Mark src='/img/x.png' />
                    </SignUpError>
                    <FakeDiv />
                </SignUpDiv>
            }
        </SignUpSection>
        <JellyPerv src='/img/JellyPerv.png' />
    </BackgroundDiv >
}
